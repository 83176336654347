import React from "react";
import { Pencil } from "tabler-icons-react";
import axios from "axios";

const Account = (props) => {
  const [name, setName] = React.useState(props.user.name);
  const [surname, setSurname] = React.useState(props.user.surname);
  const [email, setEmail] = React.useState(props.user.email);

  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");

  const imageInput = React.useRef(null);

  return (
    <div className="account">
      <h3>Account</h3>
      <div className="setting">
        <p>Edit your acount at SKXV ID</p>
        <button onClick={() => window.open("https://id.skxv.dev", "_blank")}>
          Edit account details
        </button>
      </div>
    </div>
  );
};

export default Account;
