import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import axios from 'axios';

import "./global.scss";
import { getUser } from './helpers/localStorage';
import { ModalManager } from './helpers/ModalManager';

import Login from './routes/Login/Login';
import Dashboard from './routes/Dashboard/Dashboard';

const App = () => {
  const [user, setUser] = React.useState(null);

  const getUserDetails = async () => {
    const localUser = getUser();
    if (localUser) {
      await axios.get(`https://hourcounter-production.up.railway.app/user?id=${localUser._id}`).then((res) => {
        setUser(res.data);
      }).catch((err) => {
        console.log(err.data);
      });
    }
  }

  React.useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <ModalManager>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/login" element={<Login user={user} setUser={(e) => setUser(e)}/>} />
            <Route path="/" element={<Dashboard user={user} />} />
          </Routes>
        </Router>
      </div>
    </ModalManager>
  );
}

export default App;
