import React from "react";
import { X } from "tabler-icons-react";

import "./Settings.scss";
import Account from "./pages/Account";
import Workspace from "./pages/Workspace";

const Settings = (props) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const items = [
    { name: "Account", component: <Account user={props.user} /> },
    {
      name: "Workspace",
      component: <Workspace user={props.user} hcUser={props.hcUser} />,
    },
  ];

  return (
    <div className="Settings">
      <div className="tabs">
        <X className="close" onClick={() => props.close()} />
        {items.map((item, index) => (
          <div
            className={`tab ${index == activeTab ? "active" : ""}`}
            key={index}
            onClick={() => setActiveTab(index)}
          >
            {item.name}
          </div>
        ))}
      </div>
      <div className="activeTab">{items[activeTab].component}</div>
    </div>
  );
};

export default Settings;
