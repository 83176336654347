import React from "react";
import axios from "axios";
import { Pencil } from "tabler-icons-react";

const Workspace = (props) => {
  const [workspace, setWorkspace] = React.useState({});

  const [newWorkspaceName, setNewWorkspaceName] = React.useState("");
  const [newWorkspaceColor, setnewWorkspaceColor] = React.useState("");
  const [newWorkspacePay, setNewWorkspacePay] = React.useState(0);
  const [newWorkspaceTax, setNewWorkspaceTax] = React.useState(0);

  const colorPicker = React.useRef(null);

  const getWorkspace = async () => {
    await axios
      .get(
        `https://hourcounter-production.up.railway.app/workspace?id=${props.hcUser.activeWorkspace}`
      )
      .then((res) => {
        console.log(res);
        setWorkspace(res.data);
        setNewWorkspaceName(res.data.name);
        setnewWorkspaceColor(res.data.color);
        setNewWorkspacePay(res.data.pay);
        setNewWorkspaceTax(res.data.tax);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const saveWorkspace = async () => {
    await axios
      .post("https://hourcounter-production.up.railway.app/updateWorkspace", {
        id: workspace._id,
        name: newWorkspaceName,
        color: newWorkspaceColor,
        pay: newWorkspacePay,
        tax: newWorkspaceTax,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    console.log(props);
    if (props.hcUser) {
      getWorkspace();
      console.log("aa");
    }
  }, [props.hcUser]);
  return (
    <div className="workspace">
      <h3>Workspace: {workspace.name}</h3>
      <div className="setting">
        <p className="label">Workspace name</p>
        <div className="input">
          <input
            type="text"
            placeholder="New workspace name..."
            value={newWorkspaceName}
            onChange={(e) => setNewWorkspaceName(e.target.value)}
          />
        </div>
      </div>
      <div className="setting">
        <div className="label">Workspace color</div>
        <div className="input">
          <button
            className="icon"
            style={{ background: newWorkspaceColor }}
            onClick={() => colorPicker.current.click()}
          >
            Click to edit
          </button>
          <input
            type="color"
            ref={colorPicker}
            value={newWorkspaceColor}
            onChange={(e) => setnewWorkspaceColor(e.target.value)}
            style={{ display: "none" }}
          />
        </div>
      </div>
      <div className="setting">
        <p className="label">Pay</p>
        <div className="input">
          <input
            type="text"
            placeholder="What is your hourly rate?"
            value={newWorkspacePay}
            onChange={(e) => setNewWorkspacePay(e.target.value)}
          />
        </div>
      </div>
      <div className="setting">
        <p className="label">Tax (in percent)</p>
        <div className="input">
          <input
            type="number"
            placeholder="What is your tax rate in %?"
            value={newWorkspaceTax}
            onChange={(e) => setNewWorkspaceTax(e.target.value)}
          />
        </div>
        <button onClick={() => saveWorkspace()}>Save workspace</button>
      </div>
      <div className="setting">
        <p className="label">Danger zone</p>
        <button className="danger">Delete workspace</button>
      </div>
    </div>
  );
};

export default Workspace;
