import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useModal } from "../../helpers/ModalManager";

import Account from "../../components/Account/Account";
import EditWork from "../../components/EditWork/EditWork";
import formatTime from "../../helpers/formatTime";
import WorksDetails from "../../components/WorksDetails/WorksDetails";

import "./Dashboard.scss";
import getMonthlyWork from "../../helpers/getMonthlyWork";
import { useQuery } from "../../helpers/useQuery";

const Dashboard = (props) => {
  const { create, close } = useModal();
  const [timerInterval, setTimerInterval] = React.useState(null);
  const [seconds, setSeconds] = React.useState(0);
  const [time, setTime] = React.useState("00:00:00");

  const [oldWorks, setOldWorks] = React.useState([]);
  const [groupedWorks, setGroupWorks] = React.useState([]);

  const [currentTimerId, setCurrentTimerId] = React.useState(null);
  const [workspace, setWorkspace] = React.useState(null);

  const [mobileView, setMobileView] = React.useState(false);

  const backlog = React.useRef(null);

  const [user, setUser] = React.useState(null);
  const [hcUser, setHcUser] = React.useState(null);

  const getWorkspace = async () => {
    await axios
      .get(
        `https://hourcounter-production.up.railway.app/workspace?id=${hcUser.activeWorkspace}`
      )
      .then((e) => {
        setWorkspace(e.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getOldWorks = async () => {
    await axios
      .get(
        `https://hourcounter-production.up.railway.app/work?workspace=${hcUser.activeWorkspace}`
      )
      .then((res) => {
        setOldWorks(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const calculateEarnings = (hourlyRate, seconds) => {
    const hours = seconds / 3600;
    const earnings = hourlyRate * hours;
    return earnings.toFixed(2);
  };

  React.useEffect(() => {
    if (oldWorks) {
      // Group all works by date
      const groupedWorks = oldWorks.reduce((acc, work) => {
        const date = new Date(work.date).toLocaleDateString("da-DK", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(work);
        return acc;
      }, {});
      setGroupWorks(groupedWorks);
    }
  }, [oldWorks]);

  React.useEffect(() => {
    if (hcUser) {
      getOldWorks();
    }
  }, [timerInterval, hcUser]);

  React.useEffect(() => {
    console.log("oldworks", oldWorks);
  }, [oldWorks]);

  const startTimer = async () => {
    const startTime = Date.now();
    //hourcounter-production.up.railway.app
    https: await axios
      .post("https://hourcounter-production.up.railway.app/work", {
        user: hcUser,
        workspace: hcUser.activeWorkspace,
        date: new Date(),
      })
      .then((res) => {
        setCurrentTimerId(res.data._id);
        localStorage.setItem(
          "currentTimer",
          JSON.stringify({ id: res.data._id, date: res.data.date })
        );
      })
      .catch((err) => {
        console.error(err);
      });

    setSeconds(1);
    setTimerInterval(
      setInterval(() => {
        const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
        setSeconds(elapsedSeconds + 1);
      }, 1000)
    );
  };

  const stopTimer = async () => {
    clearInterval(timerInterval);
    setTimerInterval(null);
    setSeconds(0);
    setTime("00:00:00");
    await axios
      .post("https://hourcounter-production.up.railway.app/timer", {
        id: currentTimerId,
        seconds: seconds,
        endDate: new Date(),
      })
      .then((res) => {
        console.log(res);
        getOldWorks();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const resumeTimer = async (id) => {
    await axios
      .get(`https://hourcounter-production.up.railway.app/timer?id=${id}`)
      .then((res) => {
        const timerStartDate = new Date(res.data.date);
        const now = new Date();
        const elapsedSeconds = Math.floor((now - timerStartDate) / 1000);
        setSeconds(elapsedSeconds);

        setTimerInterval(
          setInterval(() => {
            const elapsedSeconds = Math.floor(
              (Date.now() - timerStartDate) / 1000
            );
            setSeconds(elapsedSeconds);
          }, 1000)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteWork = async (id) => {
    console.log(id);
    await axios
      .delete(`https://hourcounter-production.up.railway.app/work?id=${id}`)
      .then((res) => {
        console.log(res);
        getOldWorks();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const openEditWork = (work) => {
    const id = Date.now();
    create({
      id,
      title: false,
      body: <EditWork work={work} user={props.user} close={() => close(id)} />,
      buttons: false,
      onClose: () => close(id),
    });
  };

  const openWorkDetails = (date, works) => {
    const id = Date.now();
    create({
      id,
      title: false,
      body: (
        <WorksDetails
          close={() => close(id)}
          date={date}
          works={works}
          backlog={oldWorks}
        />
      ),
      buttons: false,
      onClose: () => close(id),
    });
  };

  React.useEffect(() => {
    if (timerInterval) {
      setTime(formatTime(seconds));
    }
  }, [seconds]);

  React.useEffect(() => {
    if (backlog.current) {
      backlog.current.scrollTop = backlog.current.scrollHeight;
    }
  }, [groupedWorks]);

  React.useEffect(() => {
    if (hcUser) {
      if (hcUser.activeTimer) {
        resumeTimer(hcUser.activeTimer);
        setCurrentTimerId(hcUser.activeTimer);
      }

      if (hcUser.activeWorkspace) {
        getWorkspace();
      }
    }
  }, [hcUser]);

  /*const navigate = useNavigate();
  React.useEffect(() => {
    if (!props.user) {
      navigate("/");
    }
  });*/

  const query = useQuery();
  const navigate = useNavigate();

  const getUser = async (token) => {
    await axios
      .get("https://skxv-id-production.up.railway.app/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUser(res.data.user);
      })
      .catch((err) => {
        console.error(err);
        localStorage.removeItem("token");
        navigate("/login");
      });
  };

  const getHcUser = async (token) => {
    await axios
      .get(
        `https://hourcounter-production.up.railway.app/getUser?userId=${user._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setHcUser(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    const token = query.get("token");

    if (token) {
      localStorage.setItem("token", token);
      getUser(token);
      return;
    }

    if (localStorage.getItem("token")) {
      getUser(localStorage.getItem("token"));
      return;
    }

    navigate("/login");
  }, []);

  React.useEffect(() => {
    if (user) {
      getHcUser(localStorage.getItem("token"));
    }
  }, [user]);

  React.useEffect(() => {
    if (hcUser) {
      console.log(hcUser);
    }
  }, [hcUser]);

  return (
    <div className="Dashboard">
      {user && hcUser && (
        <>
          <Account
            user={user}
            hcUser={hcUser}
            setMobileView={(e) => setMobileView(e)}
            mobileView={mobileView}
          />
          <div className={`timer ${timerInterval ? "active" : ""}`}>
            <div className="wrapper">
              <h1 className={`${timerInterval ? "active" : ""}`}>{time}</h1>
              <button
                onClick={() => {
                  if (timerInterval) {
                    stopTimer();
                  } else {
                    startTimer();
                  }
                }}
              >
                {timerInterval ? "Stop the timer" : "Start the timer"}
              </button>
            </div>
          </div>
          <div
            className={`backlog${mobileView ? " mobileView" : ""}`}
            ref={backlog}
          >
            <h1 className="title">This is the beginning of your backlog</h1>
            {oldWorks.length === 0 && (
              <p className="subtitle">
                It's looking kinda empty. Start the timer to add some hours!
              </p>
            )}
            {Object.entries(groupedWorks).map(([date, works], index) => (
              <div className="day">
                <div className="seperator">
                  <p
                    className="date"
                    onClick={() => openWorkDetails(date, works)}
                  >
                    {date} - Click to see details
                  </p>
                </div>
                <table>
                  <tr>
                    <th>Start time</th>
                    <th>Time worked</th>
                    <th>Money earned</th>
                    <th>Actions</th>
                  </tr>
                  {works.map((work) => (
                    <tr>
                      <td>{new Date(work.date).toLocaleTimeString("en-US")}</td>
                      <td>{formatTime(work.seconds)}</td>
                      <td>
                        {workspace && workspace.pay ? (
                          calculateEarnings(workspace.pay, work.seconds)
                        ) : (
                          <span className="not-set">Not set</span>
                        )}
                      </td>
                      <td className="actions">
                        <button
                          className="delete danger"
                          onClick={() => deleteWork(work._id)}
                        >
                          Delete
                        </button>
                        <button
                          className="edit"
                          onClick={() => openEditWork(work)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td>
                      Total:{" "}
                      {formatTime(
                        works.reduce((acc, work) => acc + work.seconds, 0)
                      )}
                    </td>
                    <td>
                      Total:{" "}
                      {workspace && workspace.pay
                        ? calculateEarnings(
                            workspace.pay,
                            works.reduce((acc, work) => acc + work.seconds, 0)
                          )
                        : "Not set"}
                    </td>
                    <td></td>
                  </tr>
                </table>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
