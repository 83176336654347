import React from "react";
import { useModal } from "../..//helpers/ModalManager";
import axios from "axios";
import { clearUser } from "../../helpers/localStorage";
import { Menu2 } from "tabler-icons-react";

import "./Account.scss";
import Settings from "../Settings/Settings";
import NewWorkspace from "../newWorkspace/newWorkspace";

const Account = (props) => {
  const { create, close } = useModal();
  const element = React.useRef(null);
  const [workspaces, setWorkspaces] = React.useState([]);

  const setActiveWorkspace = async (workspace) => {
    await axios
      .post("https://hourcounter-production.up.railway.app/activeWorkspace", {
        user: props.hcUser._id,
        workspace: workspace,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getWorkspaces = async () => {
    await axios
      .get(
        `https://hourcounter-production.up.railway.app/workspaces?user=${props.hcUser._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setWorkspaces(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const openSettings = () => {
    const id = Date.now();
    element.current.blur();
    create({
      id,
      title: false,
      body: (
        <Settings
          user={props.user}
          hcUser={props.hcUser}
          close={() => close(id)}
        />
      ),
      buttons: false,
      onClose: () => close(id),
    });
  };

  const newWorkspace = async () => {
    const id = Date.now();
    element.current.blur();

    create({
      id,
      title: false,
      body: <NewWorkspace user={props.hcUser} />,
      buttons: false,
      onClose: () => close(id),
    });
  };

  const logOut = async () => {
    clearUser();
    window.location.reload();
  };

  React.useEffect(() => {
    if (props.hcUser) {
      getWorkspaces();
    }
  }, [props.hcUser]);

  return (
    <>
      <div
        className={`Account${props.mobileView ? " mobileView" : ""}`}
        tabIndex={0}
        ref={element}
        onMouseEnter={() => {
          //element.current.focus();
        }}
      >
        {props.user && (
          <>
            <img src={props.user.image} alt="" />
            <div className="info">
              <p className="name">{props.user.name}</p>
              <p className="email">{props.user.email}</p>
            </div>

            <div className="dropdown open">
              <div className="workspaces">
                {workspaces.map((workspace) => (
                  <div
                    className={`workspace ${
                      workspace._id === props.hcUser.activeWorkspace
                        ? "active"
                        : ""
                    }`}
                    key={workspace._id}
                    onClick={() => setActiveWorkspace(workspace._id)}
                  >
                    <div
                      className="icon"
                      style={{ background: workspace.color }}
                    >
                      {workspace.name.substring(0, 2) || ""}
                    </div>
                    <p className="name">{workspace.name}</p>
                  </div>
                ))}
                <div className="workspace add" onClick={() => newWorkspace()}>
                  <div className="icon">+</div>
                  <p>Add Workspace</p>
                </div>
              </div>
              <div className="item settings" onClick={() => openSettings()}>
                Settings
              </div>
              <div className="item logout" onClick={() => logOut()}>
                Logout
              </div>
            </div>
          </>
        )}
      </div>
      <button
        className="viewBacklog"
        onClick={() => props.setMobileView(!props.mobileView)}
      >
        <Menu2 />
      </button>
    </>
  );
};

export default Account;
