import React from "react";
import { Pencil } from "tabler-icons-react";
import axios from "axios";
import formatTime from "../../../helpers/formatTime";

const Account = (props) => {
  const [totalWorked, setTotalWorked] = React.useState(props.work.seconds);

  const saveTime = async () => {
    await axios
      .post("https://hourcounter-production.up.railway.app/workTime", {
        id: props.work._id,
        seconds: totalWorked,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteWork = async () => {
    await axios
      .delete(
        `https://hourcounter-production.up.railway.app/work?id=${props.work._id}`
      )
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="account">
      <h3>Details</h3>
      <div className="settings">
        <div className="setting">
          <p className="label">Time worked</p>
          <input type="text" value={formatTime(totalWorked)} disabled />
          <p className="label">Time worked in seconds</p>
          <input
            type="number"
            value={totalWorked}
            onChange={(e) => setTotalWorked(e.target.value)}
            disabled
          />
          <div className="row">
            <button onClick={() => setTotalWorked(totalWorked + 3600)}>
              Add one hour
            </button>
            <button
              onClick={() => {
                if (totalWorked > 3600) {
                  setTotalWorked(totalWorked - 3600);
                }
              }}
            >
              Remove one hour
            </button>
          </div>
          <div className="row">
            <button
              onClick={() => {
                setTotalWorked(totalWorked + 1800);
              }}
            >
              Add 30 min
            </button>
            <button
              onClick={() => {
                if (totalWorked > 1800) {
                  setTotalWorked(totalWorked - 1800);
                }
              }}
            >
              Remove 30 min
            </button>
          </div>
          <button className="save" onClick={() => saveTime()}>
            Save
          </button>
        </div>

        <div className="setting">
          <p className="label">Danger zone</p>
          <button onClick={() => deleteWork()} className="danger">
            Delete work
          </button>
        </div>
      </div>
    </div>
  );
};

export default Account;
