const getMonthlyWork = (month, year, backlog) => {
    let totalSeconds = 0;
    backlog.forEach((work) => {
      const workDate = new Date(work.date);
      if (workDate.getMonth() === month && workDate.getFullYear() === year) {
        totalSeconds += work.seconds;
      }
    });
    return totalSeconds;
  };

export default getMonthlyWork;