import React from "react";
import { X } from "tabler-icons-react";

import "./EditWork.scss";
import Account from "./pages/Account";
import Workspace from "./pages/Workspace";

const EditWork = (props) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const items = [
    {
      name: "Details",
      component: <Account user={props.user} work={props.work} />,
    },
    {
      name: "Notes",
      component: <Workspace user={props.user} work={props.work} />,
    },
  ];

  return (
    <div className="Settings">
      <div className="tabs">
        <X className="close" onClick={() => props.close()} />
        {items.map((item, index) => (
          <div
            className={`tab ${index == activeTab ? "active" : ""}`}
            key={index}
            onClick={() => setActiveTab(index)}
          >
            {item.name}
          </div>
        ))}
      </div>
      <div className="activeTab">{items[activeTab].component}</div>
    </div>
  );
};

export default EditWork;
