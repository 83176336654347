import React from "react";
import "./WorksDetails.scss";
import formatTime from "../../helpers/formatTime";
import getMonthlyWork from "../../helpers/getMonthlyWork";
import { X } from "tabler-icons-react";

const WorksDetails = (props) => {
  return (
    <div className="WorksDetails">
      {/*<h1>
        {formatTime(props.works.reduce((acc, work) => acc + work.seconds, 0))}
      </h1>
      <h1>
        {formatTime(
          getMonthlyWork(
            new Date(props.date).getMonth(),
            new Date(props.date).getFullYear(),
            props.backlog
          )
        )}
          </h1>*/}
      <div className="header">
        <X size={20} onClick={() => props.close()} />
        <h3>Details</h3>
      </div>
      <div className="settings">
        <div className="setting">
          <p className="label">Total worked {props.date}</p>
          <div className="input">
            <input
              type="text"
              value={formatTime(
                props.works.reduce((acc, work) => acc + work.seconds, 0)
              )}
              disabled
            />
          </div>
        </div>
        <div className="setting">
          <p className="label">
            Total worked in{" "}
            {new Date(props.date).toLocaleString("default", { month: "long" })}
          </p>
          <div className="input">
            <input
              type="text"
              value={formatTime(
                getMonthlyWork(
                  new Date(props.date).getMonth(),
                  new Date(props.date).getFullYear(),
                  props.backlog
                )
              )}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorksDetails;
