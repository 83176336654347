import React from "react";
import axios from "axios";

const NewWorkspace = (props) => {
  const [newWorkspaceName, setNewWorkspaceName] = React.useState("");

  const saveWorkspace = async () => {
    await axios
      .post("https://hourcounter-production.up.railway.app/workspace", {
        name: newWorkspaceName,
        user: props.user ? props.user._id : null,
        color: "#ffc9c9",
      })
      .then((e) => {})
      .catch((err) => {
        console.error(err);
      });

    window.location.reload();
  };

  return (
    <div className="newWorkspace">
      <h3>New workspace</h3>
      <input
        type="text"
        placeholder="New workspace name..."
        onChange={(e) => setNewWorkspaceName(e.target.value)}
      />
      <button onClick={() => saveWorkspace()}>Save workspace</button>
    </div>
  );
};

export default NewWorkspace;
