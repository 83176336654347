import React from "react";
import axios from "axios";

const Workspace = (props) => {
  const [notes, setNotes] = React.useState(props.work.note);

  const saveNotes = async () => {
    await axios
      .post("https://hourcounter-production.up.railway.app/workNote", {
        id: props.work._id,
        note: notes,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="workspace">
      <h3>Notes</h3>
      <div className="setting">
        <p className="label">Work notes</p>
        <textarea
          placeholder="Your notes for this entry is empty..."
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        <button onClick={() => saveNotes()}>Save notes</button>
      </div>
    </div>
  );
};

export default Workspace;
