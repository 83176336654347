import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "./Login.scss";

const Login = (props) => {
  const login = async () => {
    window.location.href =
      "https://id.skxv.dev/auth?redirect=https://hc.skxv.dev";
  };

  return (
    <div className="Login">
      <button onClick={() => login()}>Login using SKXV_ID</button>
    </div>
  );
};

export default Login;
