// localstorage.js
export const setUser = (user) => {
	localStorage.setItem("user", JSON.stringify(user));
}

export const clearUser = () => {
	localStorage.removeItem("user")
}

export const getUser = () => {
	return JSON.parse(localStorage.getItem("user"));
}